import {useState, useEffect} from 'react';

const useFetch = ({url, fallback}) => {
  const [data, setData] = useState();
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('fetching url', {url});
    fetch(url)
      .then(response => {
        console.log('response received', {url});
        if (response.ok) return response.json();
        throw response;
      })
      .then((result) => {
        console.log('response parsed', {url});
        setData(result);
      })
      .catch(err => {
        console.error('error fetching', {err});
        setData(fallback);
        setError(err);
      })
      .finally(() => {setLoading(false)});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {loading, error, data};
}

export {
  useFetch,
};
